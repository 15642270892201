import React from "react";
import Particles from "react-particles-js";
import "../assets/css/main.css";

function HomePage(props) {
  return (
    <div className="big-canvas">
      <Particles
        className="particles"
        params={{
          "particles": {
            "number": {
              "value": 160,
              "density": {
                "enable": false
              }
            },
            "size": {
              "value": 3,
              "random": true,
              "anim": {
                "speed": 4,
                "size_min": 0.3
              }
            },
            "line_linked": {
              "enable": false
            },
            "move": {
              "random": true,
              "speed": 1,
              "direction": "top",
              "out_mode": "out"
            }
          },
          "interactivity": {
            "events": {
              "onhover": {
                "enable": true,
                "mode": "bubble"
              },
              "onclick": {
                "enable": true,
                "mode": "repulse"
              }
            },
            "modes": {
              "bubble": {
                "distance": 100,
                "duration": 2,
                "size": 0,
                "opacity": 50
              },
              "repulse": {
                "distance": 200,
                "duration": 2
              }
            }
          }
        }}
      />
      <div className="text-box">
        <h1 className="rotate">Coming</h1>
        <h1>Soon</h1>
      </div>
    </div>
  );
}

export default HomePage;
